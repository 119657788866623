import React from 'react';
import { Auth } from 'aws-amplify';
import { eraseCookie } from '../../components/LandingPage/helpers/cookieHelper';

class ForceLogout extends React.Component {
        componentDidMount() {
                try {
                        eraseCookie("id_token");
                        eraseCookie("access_token");
                        eraseCookie("refresh_token");

                        localStorage.removeItem('client-id');
                        Auth.signOut()
                } catch (error) {
                        console.log(`[SSO] Force-logout: Error ${JSON.stringify(error)}`)
                }
        }

        render() { return null; }
}

export default ForceLogout;
