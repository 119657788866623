async function redirectHelper(redirect_uri, idToken, refreshToken, clientState, qsRedirectUri, qsIsNewTab) {
			const callback_url = `${redirect_uri}/?id_token=${idToken}&refresh_token=${refreshToken}${!!clientState ? `&state=${clientState}` : ``}`;
			console.log(`callback_url: ${callback_url}`)
			if (qsIsNewTab) {
				console.log("[qsIsNewTab is set up]")
				if(window.parent){
					console.log("postMessage to parent")
					window.parent.postMessage({ tnOnCloseLoginViewSuccessUrl: callback_url }, new URL(qsRedirectUri)?.origin);
				}
				if (window.opener && !window.opener.closed) {
					console.log("postMessage to opener")
					window.opener.postMessage({ tnOnCloseLoginViewSuccessUrl: callback_url }, new URL(qsRedirectUri)?.origin);
					window.close();
				}
			} else {
				window.location.replace(callback_url);
			}
}

export default redirectHelper;