import forceLogout from './forceLogout';
import isValidSession from "./isValidSession"

const checkValidityCookiesTokens = async () => {
    try {
        const isValid = await isValidSession()
        if(!isValid){
          throw new Error("Session is not valid")
        }
      } catch (e) {
        console.log('Unable to refresh Token -> Force logout', e);
        await forceLogout();
      }
};

export default checkValidityCookiesTokens;
