import { Branding } from "../../branding";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    gridLogo: {
        paddingBottom: theme.spacing(3),
    },
    grid: {
        justifyContent: 'center',
    },
    box: {
        width: '90%',
        maxWidth: 650,
    },
    card: {
        justifyContent: 'center',
    },
    cardMedia: {
        width: 300,
        height: 200,
        backgroundSize: 'contain',
    },
    cardHeader: {
        paddingBottom: 0
    },
    cardContent: {
        justifyContent: 'center',
        textAlign: 'center',
        "&:last-child": {
            paddingBottom: theme.spacing(1)
        }
    },
    boxExpandIdp: {
        textAlign: 'center',
    },
    chipExpand: {
        backgroundColor: Branding.secondary,
        color: Branding.white,
        '&:hover': {
            backgroundColor: Branding.secondary,
            opacity: Branding.opacityHover,
        },
    },
    expandIcon: {
        color: Branding.white,
    },
    divider: {
        marginBottom: theme.spacing(2)
    },
    typoToS: {
        paddingTop: theme.spacing(3),

    },
    linkTos: {
        fontSize: 14,
        '&:hover': {
            color: Branding.accent,
            opacity: Branding.opacityHover,
        },
    },
    packageVersion: {
        fontSize: 10,
        color: "gray"
    },
    midBul: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        color: Branding.secondary,
    },
    linkImprint: {
        fontSize: 14,
        '&:hover': {
            cursor: 'pointer',
            color: Branding.accent,
            opacity: Branding.opacityHover,
        },
    }
}));

export default useStyles;