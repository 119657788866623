import { Auth } from 'aws-amplify';
import Cookies from "js-cookie";
import { setTokenCookie } from './tokenHelper';

const isValidSession = async () => {
  let isValid = false;

  try {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const currentSession = await Auth.currentSession();
    await new Promise((res, rej) => {
      cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
        console.log(err ? `Session is valid` : "");
        if (err) rej()
        res(session)
      });
    })
    isValid = true;
  } catch (e) {
    isValid = false
  }

  //Fallback: looking for refresh_token stored in cookies
  if(!isValid){
    try {
      console.log(`Validating Refresh token via API...`)
      const result = await verifyRefreshToken()
      isValid = result?.success;
    } catch (err) {
      console.log({err})
    }
  }

  /**
   * Fallback: If the browser cannot read cookie and session is not valid, returns true (session is valid).
   * We must to implement: https://developers.google.com/privacy-sandbox/3pcd/storage-access-api?hl=it
   * It works only in google chrome
  */

  if(!isValid && !Cookies.get('refresh_token')){
    isValid = true;
  }

  return isValid;
};

export async function verifyRefreshToken() {
  const refreshToken = Cookies.get('refresh_token')
  if(!refreshToken) return false;

  const url = `https://amplifybroker-tn-${window.location.host==="sso.tecnichenuove.it" ? "prod" : "dev"}.auth.eu-central-1.amazoncognito.com/oauth2/token`
  const params = new URLSearchParams();
  const clientId = new URLSearchParams(window.location.search)?.get("client_id") || localStorage.getItem("client-id")
  params.append('grant_type', 'refresh_token');
  params.append('client_id', clientId);
  params.append('refresh_token', refreshToken);

  console.log(`Fetching ${url} with params: ${params.toString()} ...`)


  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: params
    });

    if (response.ok) {
      const data = await response.json();
      console.log(`√ Refresh token is valid and set new cookies`)
      setTokenCookie("id_token", data.id_token);
			setTokenCookie("access_token", data.access_token);
      console.log('Token rinnovato con successo:', data);
      // Puoi usare data.access_token e data.id_token come necessario
      return {success:true, data};
    } else {
      const errorData = await response.json();
      console.log(`X Refresh token is not valid: ${JSON.stringify(errorData)}`)
      console.error('Errore nel rinnovo del token:', errorData);
      return {success:false};
    }
  } catch (error) {
    console.log(`X Error in the request http: ${JSON.stringify(error)}`)
    console.error('Errore nella richiesta:', error);
    console.error("Called url", url);
    console.error("Params", params);
    return {success:false};
  }
}

export default isValidSession;
