import { Auth } from 'aws-amplify';
import { eraseAuthCookies } from './cookieHelper';

const forceLogout = async () => {
    eraseAuthCookies();
    localStorage.removeItem("client-id");
    await Auth.signOut();
    console.log(`Signed out...`)
};

export default forceLogout;
